import React from 'react';
import ReparationExternalBrand from "../../components/ReparationExternalBrand";
import { StaticImage } from "gatsby-plugin-image";
import { ExternalBrandEnum } from "../../data/externalBrand";
import styled from "styled-components";

const AdvContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 20px;
  background-color: #FFFFFF;
  border-radius: 8px;
  position: relative;
  height: 100%;
  
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {  
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    height: auto;
  }
`;

const AdvantageIconDiv = styled.div`
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  width: 78px;
  height: 78px;
  padding: 10px 0 0 0;
  
  & > div {
    position: absolute !important;
    margin: 7px;
  }
`;

const Span20 = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 54px;
  height: 29px;
  padding: 10px 0 0 0;
  color: #0F4C81;
  font-size: 24px;
  font-weight: bold;
  font-family: 'GothamRounded', sans-serif;
`;

const Paragraph = styled.p`
  font-size: 18px;
  font-family: Arial, sans-serif;
  color: #000000;
  text-align: left;
  line-height: 24px;
`;

const advgTitel: React.CSSProperties = {
    fontFamily: 'Arial, sans-serif',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '23px',
    textAlign: 'left',
    color: '#000000',
};

const colorBrand = ExternalBrandEnum.ORANGE.colorCode;

const Advantages: React.FC = () => {
    return (
        <AdvContainer>
            <ReparationExternalBrand.AdvantageBodyContainer>
                <AdvantageIconDiv>
                    <StaticImage
                        src="../../../static/images/services/reparation_orange/Reparation_Express.svg"
                        alt="Reparation Express"
                        placeholder="blurred"
                        quality={100}
                        width={50}
                        height={50}
                    />
                </AdvantageIconDiv>
                <ReparationExternalBrand.Paragraph>
                    <ReparationExternalBrand.AdvantageBodyText style={advgTitel} color={colorBrand}>
                        Réparation Express
                    </ReparationExternalBrand.AdvantageBodyText>
                    <Paragraph>60% des réparations réalisées en moins de 2h.</Paragraph>
                </ReparationExternalBrand.Paragraph>
            </ReparationExternalBrand.AdvantageBodyContainer>

            <ReparationExternalBrand.AdvantageBodyContainer>
                <AdvantageIconDiv>
                    <StaticImage
                        src="../../../static/images/services/reparation_orange/Bonus.svg"
                        alt="Bonus Réparation"
                        placeholder="blurred"
                        quality={100}
                        width={50}
                        height={35}
                    />
                </AdvantageIconDiv>
                <ReparationExternalBrand.Paragraph>
                    <ReparationExternalBrand.AdvantageBodyText style={advgTitel} color={colorBrand}>
                        Bonus Réparation 25€
                    </ReparationExternalBrand.AdvantageBodyText>
                    <Paragraph>
                        Sous conditions d’éligibilité du téléphone et de la panne (cumulable avec la remise 20%).
                    </Paragraph>
                </ReparationExternalBrand.Paragraph>
            </ReparationExternalBrand.AdvantageBodyContainer>

            <ReparationExternalBrand.AdvantageBodyContainer>
                <AdvantageIconDiv>
                    <StaticImage
                        src="../../../static/images/services/reparation_orange/Pieces_detachees.svg"
                        alt="Pièces détachées"
                        placeholder="blurred"
                        quality={100}
                        width={50}
                        height={50}
                    />
                </AdvantageIconDiv>
                <ReparationExternalBrand.Paragraph>
                    <ReparationExternalBrand.AdvantageBodyText style={advgTitel} color={colorBrand}>
                        Pièces détachées
                    </ReparationExternalBrand.AdvantageBodyText>
                    <Paragraph>Origine constructeur ou compatible de haute qualité.</Paragraph>
                </ReparationExternalBrand.Paragraph>
            </ReparationExternalBrand.AdvantageBodyContainer>

            <ReparationExternalBrand.AdvantageBodyContainer>
                <AdvantageIconDiv>
                    <StaticImage
                        src="../../../static/images/services/reparation_orange/Garantie.svg"
                        alt="Garantie 12 mois"
                        placeholder="blurred"
                        quality={100}
                        width={50}
                        height={50}
                    />
                </AdvantageIconDiv>
                <ReparationExternalBrand.Paragraph>
                    <ReparationExternalBrand.AdvantageBodyText style={advgTitel} color={colorBrand}>
                        Garantie 12 mois
                    </ReparationExternalBrand.AdvantageBodyText>
                    <Paragraph>Sur toute pièce changée y compris batterie.</Paragraph>
                </ReparationExternalBrand.Paragraph>
            </ReparationExternalBrand.AdvantageBodyContainer>

            <ReparationExternalBrand.AdvantageBodyContainer>
                <AdvantageIconDiv>
                    <ReparationExternalBrand.AdvantageBodyText color={colorBrand}>
                        <Span20>20%</Span20>
                    </ReparationExternalBrand.AdvantageBodyText>
                </AdvantageIconDiv>
                <ReparationExternalBrand.Paragraph>
                    <ReparationExternalBrand.AdvantageBodyText style={advgTitel} color={colorBrand}>
                        Remise 20% sur les réparations
                    </ReparationExternalBrand.AdvantageBodyText>
                    <Paragraph>
                        Sur présentation de facture abonnement mensuel mobile ou internet de moins de 3 mois,
                        ou défaut facture d'achat de téléphone chez Orange, à votre nom.
                    </Paragraph>
                </ReparationExternalBrand.Paragraph>
            </ReparationExternalBrand.AdvantageBodyContainer>
        </AdvContainer>
    );
};

export default Advantages;
